import axios from 'axios';
import {
  RegistrationWorkflowFormField,
  RegistrationWorkflowWaiver,
  TeamRegistration,
} from '@la/types';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

type UserRegistration = {
  deleted: boolean;
  id: string;
  siteId: number;
  programId: number;
  registrationType: string;
  teamId: string;
  paymentStatus: string;
  registeringUserId: number;
  registrationStatus: string;
  teamIdOg: number;
  metadata?: {
    formFields?: RegistrationWorkflowFormField[];
    waivers?: RegistrationWorkflowWaiver[];
    roleId?: number;
    programStaffId?: number;
    registeredUserId: number;
  };
};

const baseUrl = getBaseURL();

const getUserRegistrations = async ({ userId }: { userId: string }) => {
  const endpoint = `${baseUrl}/api/registration/users/${userId}/registrations`;
  const token = extractAccessToken();
  const response = await axios.get<UserRegistration[]>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

const getUserPlayerRegistrations = async ({
  programId,
  siteDomain,
}: {
  programId: string;
  siteDomain: string;
}) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/registration?programId=${programId}`;
  const token = extractAccessToken();

  const response = await axios.get<{ userRegistrations: TeamRegistration[] }>(
    endpoint,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
};

/**
 * Retrieves the existing user registration (if there is one).
 * @param registrationType Determines what criteria to check when getting the user registration.
 * @param userId Id of the user the registration should be under.
 * @param programId Id of the program the registration should be under. Used when
 * `registrationType` is `STAFF`.
 * @param teamIdOg Id of the team the registration should be under. Used when `registrationType`
 * is `PLAYER`.
 * @param roleId Id of the role the registration should be under. Used when `registrationType` is `STAFF`.
 * @returns The existing `UserRegistration` or `undefined` if the criteria is not met.
 */
const getExistingUserRegistration = async ({
  registrationType,
  userId,
  programId,
  teamIdOg,
  roleId,
}: {
  registrationType: 'PLAYER' | 'STAFF';
  userId: number;
  programId: number;
  teamIdOg: number;
  roleId?: number;
}): Promise<UserRegistration | undefined> => {
  const userRegistrations = await getUserRegistrations({
    userId: userId.toString(),
  });

  return userRegistrations.find((userRegistration) => {
    const hasMatchingTypeAndStatus =
      userRegistration.registrationType === registrationType &&
      (userRegistration.metadata?.registeredUserId === userId ||
        userRegistration.registeringUserId === userId) &&
      userRegistration.registrationStatus !== 'REMOVED';

    if (hasMatchingTypeAndStatus) {
      if (registrationType === 'PLAYER') {
        return userRegistration.teamIdOg === teamIdOg;
      }

      return (
        userRegistration.programId === programId &&
        userRegistration.metadata?.programStaffId === roleId &&
        userRegistration.teamIdOg === teamIdOg
      );
    }

    return false;
  });
};

export {
  getUserRegistrations,
  getExistingUserRegistration,
  getUserPlayerRegistrations,
};
