import { useEffect } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLAHostnameParts } from '@la/utilities';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useLazyGetProgramQuery } from 'redux/services/activity';
import {
  useGetCartSummaryQuery,
  useGetPaymentWaiverQuery,
} from 'redux/services/checkoutApi';
import { useLazyGetRegistrationIdsQuery } from 'redux/services/registrationApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { BillingSummaryRequestItem, CartSummaryData } from './Checkout.types';
import CheckoutPageAlerts from './CheckoutPageAlert/CheckoutPageAlerts';
import CheckoutRollup from './CheckoutRollup/CheckoutRollup';
import OrderSummaryCard from './OrderSummaryCard/OrderSummaryCard';
import PaymentMethodCard from './PaymentMethodCard/PaymentMethodCard';
import PaymentOptionsCard from './PaymentOptionsCard/PaymentOptionsCard';
import TermsAndConditionsAgreementBox from './TermsAndConditionsAgreementBox/TermsAndConditionsAgreementBox';
import { getPaymentPlanSubTotal } from './utils/paymentPlan';
import {
  MajorColumn,
  MinorColumn,
} from './CartSummary/CartSummaryBody/CartSummaryBody.styles';
import * as S from './Checkout.styles';

/* Checkout */
export default function ConsolidatedCheckout() {
  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { data: userId } = useGetUserIdQuery(siteId);
  const skip = !userId || !siteId;

  const { cartCheckoutPaymentTerms } = useFlags();

  const { data, error, isLoading } = useGetCartSummaryQuery(
    {
      siteId,
      userId,
      includePaymentPlanSummary: cartCheckoutPaymentTerms,
    },
    { skip }
  );

  useEffect(() => {
    document.title = siteName ? `${siteName}: Checkout` : 'Checkout';
  }, [siteName]);

  return (
    <S.Checkout id="checkout-page">
      <PageTitle>Checkout</PageTitle>
      {isLoading ? <div>Loading...</div> : null}
      {data ? (
        <CheckoutPageContent
          data={data}
          error={error}
          siteId={siteId}
          userId={userId}
        />
      ) : null}
    </S.Checkout>
  );
}
/* */

/* Checkout Page Content */
export function CheckoutPageContent({
  data,
  error,
  siteId,
  userId,
}: {
  data: CartSummaryData;
  error?: FetchBaseQueryError | SerializedError;
  siteId: string | null;
  userId?: number | null;
}) {
  const { subdomain } = getLAHostnameParts();

  const { data: paymentWaiver /*, isLoading, isError, error */ } =
    useGetPaymentWaiverQuery({
      siteId,
    });
  const [getProgram] = useLazyGetProgramQuery();
  const [getRegistrationIds] = useLazyGetRegistrationIdsQuery();

  const {
    selectedPaymentOptions,
    registrationAnalyticsData,
    updateCartHasAutopayPaymentOption,
    updateHasAutopayPaymentOptionSelected,
    updateRegistrationAnalyticsData,
    updateSelectedPaymentOptions,
  } = useCheckoutInfo();

  /**
   * Update the context with the analytics data needed to
   * render on the confirmation page.
   */
  useEffect(() => {
    if (data?.cartItems && siteId && userId) {
      Promise.all(
        data?.cartItems.flatMap(async (item) => {
          const { program, subprograms } = item.options;
          const registrations = await Promise.all(
            subprograms.details.map(async (subprogram) => {
              const { data: subprogramData } = await getProgram({
                siteId,
                programId: subprogram.subprogramId.toString(),
              });

              const registrationIds = await getRegistrationIds({
                siteDomain: subdomain,
                registrationIds: subprogram.registrations.map((r) =>
                  r.registrationId.toString()
                ),
              });

              return Promise.all(
                subprogram.registrations.map((registration) => ({
                  programFullName: program.programName,
                  programId: subprogram.subprogramId,
                  programSport: subprogramData?.details?.sport,
                  programType: program.programType.toUpperCase(),
                  registrationUserId: userId.toString(),
                  registrationId:
                    registrationIds.data?.[
                      registration.registrationId.toString()
                    ].registrationIdOg.toString(),
                }))
              );
            })
          );

          return registrations.flat();
        })
      ).then((data) => {
        const newData = data.flat();
        if (
          JSON.stringify(registrationAnalyticsData) !== JSON.stringify(newData)
        ) {
          updateRegistrationAnalyticsData(newData);
        }
      });
    }
  }, [
    data?.cartItems,
    getProgram,
    getRegistrationIds,
    siteId,
    subdomain,
    userId,
    registrationAnalyticsData,
    updateRegistrationAnalyticsData,
  ]);

  useEffect(() => {
    const { cartItems } = data;

    const cartHasAutopayPaymentPlan = cartItems.some(
      (item) =>
        !!item.options.program.paymentPlanSummaries?.find(
          (paymentPlan) => paymentPlan.autopayRequired
        )
    );

    if (!selectedPaymentOptions) {
      const selectedPaymentOptions: BillingSummaryRequestItem[] = cartItems.map(
        (cartItem) => {
          const { paymentPlanSummaries } = cartItem.options.program;

          if (
            paymentPlanSummaries?.length &&
            (!cartItem.options.program.paymentRules?.payInFull ||
              Number(cartItem.subtotal) >
                getPaymentPlanSubTotal(paymentPlanSummaries[0]))
          ) {
            const firstPaymentPlan = paymentPlanSummaries[0];

            return {
              autopayRequired: firstPaymentPlan.autopayRequired,
              cartItemUuid: cartItem.cartItemUuid,
              payLater: false,
              paymentTerm: 'PAYMENT_PLAN',
              paymentPlanId: firstPaymentPlan.ngPaymentPlanId,
            };
          }

          return {
            autopayRequired: false,
            cartItemUuid: cartItem.cartItemUuid,
            payLater: false,
            paymentTerm: 'FULL',
          };
        }
      );

      const hasAutopayPaymentOptionSelected = selectedPaymentOptions.some(
        (paymentTerm) => paymentTerm.autopayRequired
      );

      updateHasAutopayPaymentOptionSelected(hasAutopayPaymentOptionSelected);
      updateSelectedPaymentOptions(selectedPaymentOptions);
    }

    updateCartHasAutopayPaymentOption(cartHasAutopayPaymentPlan);
  }, [
    data,
    selectedPaymentOptions,
    updateCartHasAutopayPaymentOption,
    updateHasAutopayPaymentOptionSelected,
    updateSelectedPaymentOptions,
  ]);

  return (
    <ColumnGrid>
      <CheckoutPageAlerts />
      <MajorColumn>
        <OrderSummaryCard cartItems={data.cartItems} />
        <PaymentOptionsCard cartItems={data.cartItems} />
        <PaymentMethodCard />
        {paymentWaiver ? (
          <TermsAndConditionsAgreementBox
            policyContent={paymentWaiver.content}
            policyName={paymentWaiver.name}
          />
        ) : null}
      </MajorColumn>
      <MinorColumn>
        <CheckoutRollup />
      </MinorColumn>
    </ColumnGrid>
  );
}
/* */
