import axios, { AxiosError } from 'axios';
import { WorkflowRegistrationData } from '@la/types';
import { extractAccessToken } from '@la/utilities';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const updateWorkflowRegistration = async (
  payload: WorkflowRegistrationData & {
    workflowVersion?: number;
    finalize?: boolean;
  },
  siteDomain: string
) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/registrations/updateRegistrations`;
  const token = extractAccessToken();
  try {
    return axios.post(endpoint, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    const { status, message } = e as AxiosError;
    console.error(`Error updating registrations: [${status}] ${message}`);
    throw e;
  }
};

export { updateWorkflowRegistration };
