import { forwardRef, useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import {
  Card,
  CardProps,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import {
  getSiteId,
  getUserId,
  useAppSelector,
  useUpdateTeamMutation,
} from '@la/services';
import { FormField } from '@la/types';
import {
  getLAHostnameParts,
  mapFormFieldToWorkflow,
  removeDuplicateFormFields,
} from '@la/utilities';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { formatDateRange } from 'lib/utils/dateUtils';
import { Team } from 'redux/services/types/team';
import { Division } from 'redux/services/types/tournament';
import {
  DivisionCardState,
  DivisionCardsState,
  DivisionUnavailable,
} from 'domains/Tournaments/Registration/Wizard/Wizard.types';
import {
  CreateTeamForm,
  CreateTeamFormFields,
} from 'domains/Tournaments/Registration/Wizard/components/CreateTeamForm/CreateTeamForm';
import { uploadCustomFileField } from 'domains/Tournaments/utils/uploadCustomFileField';
import { EditTeamFieldsModal } from '../EditTeamFieldsModal/EditTeamFieldsModal';
import { DivisionCardFooter } from './DivisionCardFooter/DivisionCardFooter';
import { DivisionCardHeader } from './DivisionCardHeader/DivisionCardHeader';
import { DivisionCardInfoSection } from './DivisionCardInfoSection/DivisionCardInfoSection';
import { DivisionCardInfoRightSection } from './DivisionCardInfoSectionRight/DivisionCardInfoSectionRight';
import {
  DivisionCardTeamSelectionSection,
  getTeamSelectId,
} from './DivisionCardTeamSelectionSection/DivisionCardTeamSelectionSection';
import { UpdateTeamDialogProps } from './DivisionCardTeamSelectionSection/UpdateTeamDialog';
import { TeamSelectionSection } from './TeamSelectionSection/TeamSelectionSection';
import { getIncompleteRequiredFieldsTeams } from './TeamSelectionSection/utils/validation';
import { getNumberOfSpotsLeft } from './utils/capacity';
import * as S from './DivisionCard.styles';

export const teamFields = ['name', 'organization'] as const;

export type TeamField = (typeof teamFields)[number];

type DialogOpen = '' | 'discard-confirmation' | 'create-team';

export type BaseDivisionCardProps = {
  /**
   * Meta-data related to this division.
   */
  division: Division;
  /**
   * When true, the division dates are displayed in the card
   */
  showDates?: boolean;
  /**
   * When true, the division location is displayed in the card if it is defined
   */
  showLocation?: boolean;
};

export type UnavailableDivisionCardProps = BaseDivisionCardProps & {
  cardState: DivisionUnavailable;
};

export type AvailableDivisionCardProps = BaseDivisionCardProps &
  Pick<UpdateTeamDialogProps, 'onUpdateTeam'> & {
    /**
     * The teams allowed to register for this division.
     */
    availableTeams: Team[];
    /**
     * The state the division card is in along with the props that are relevant in
     * that specific state.
     */
    cardState: DivisionCardState;
    /**
     * Card states of all divisions in the tournament.
     */
    divisionCardsState: DivisionCardsState;
    /**
     * The max age group that a team can be to be registered for this division.
     */
    maxDivisionAgeGroup: number;
    /**
     * Id of the tournament this division is in.
     */
    tournamentId: string;
    /**
     * Called when the "Add another team" button is pressed
     */
    onAddTeamSelect: () => void;
    /**
     * Called when the cancel button is pressed
     */
    onCancel: () => void;
    /**
     * Called when the division is fully cleared
     */
    onClear: () => void;
    /**
     * Called when a team is deleted
     * @param teamId The id of the team that was deleted
     */
    onDeleteTeam: (teamId: string) => void;
    /**
     * Called when the discard button is pressed for a team select
     * @param selectIndex The array index of the select is being deleted
     */
    onDiscardTeamSelect: (selectIndex: number) => void;
    /**
     * Called when the edit division button is pressed
     */
    onEditDivision: () => void;
    /**
     * Called when the division is selected
     */
    onSelectDivision: () => void;
    /**
     * Called when the save button is pressed
     */
    onSave: () => void;
    /**
     * Called when the value of a team select changes
     * @param selectIndex The array index of the select that was updated
     * @param value The new value of the team select - either the ID of a team
     * or an empty string if the select was cleared.
     */
    onSelectTeam: (selectIndex: number, value: string) => void;
    /**
     * Called when the user submits the `CreateTeamForm` successfully.
     * @param divisionId The id of the division
     * @param selectIndex The id of the select that the new team should be assigned to
     * @param team The new team
     */
    onTeamCreate: (
      divisionid: string,
      selectIndex: number,
      team: Omit<Team, 'id' | 'ageGroup'>
    ) => Promise<void>;
    setHasDeleteTeamDivisionError: (error: boolean) => void;
  };

export type DivisionCardProps =
  | UnavailableDivisionCardProps
  | AvailableDivisionCardProps;

export const DISCARD_CONFIRMATION_DIALOG_TITLE = 'Remove team';
export const DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT = 'Yes, remove this';

export const DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE =
  'Select "Save division" to save your current changes or "Cancel" to discard them.';

function DiscardConfirmationDialog({
  isOpen,
  onOpenChange,
  onClearDivision,
  close,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onClearDivision: () => void;
  close: () => void;
}) {
  const handleConfirmation = () => {
    onClearDivision();
    close();
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={onOpenChange}
      primaryAction={
        <ModalPrimaryActionButton onClick={handleConfirmation}>
          {DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT}
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
      size="medium"
      title={DISCARD_CONFIRMATION_DIALOG_TITLE}
    >
      You are now removing this team and division from your registrations.
      <S.ConfirmationSentence>
        Are you sure you want to continue?
      </S.ConfirmationSentence>
    </Modal>
  );
}

function CreateTeamDialog({
  maxDivisionAgeGroup,
  displayAgeGroupOptions = true,
  isOpen,
  onOpenChange,
  onTeamCreate,
  customFields,
}: {
  maxDivisionAgeGroup: number;
  displayAgeGroupOptions?: boolean;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onTeamCreate: (team: CreateTeamFormFields) => Promise<void>;
  customFields: FormField[];
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const userId = useAppSelector(getUserId);
  const siteId = useAppSelector(getSiteId);

  const formId = 'create-team-form';

  /**
   * When a submission error occurs, the error is shown at the top of
   * the form in the modal's body. Beause the content of the body is
   * scrollable, we want to scroll the user to the top so they can
   * see it immediately.
   */
  useEffect(() => {
    const form = formRef.current;
    if (hasSubmitError && form && form.parentNode) {
      const formParentNode = form.parentNode as HTMLElement;
      if (!!formParentNode.scrollTo) {
        formParentNode.scrollTo({
          top: 0,
        });
      }
    }
  }, [hasSubmitError]);

  const onSubmit = async (team: CreateTeamFormFields): Promise<void> => {
    setHasSubmitError(false);
    setIsSubmitting(true);

    const formattedFields = await uploadCustomFileField({
      fields: team.customTeamFields,
      siteId,
      userId,
    });

    onTeamCreate({ ...team, customTeamFields: formattedFields })
      .then(() => setIsSubmitting(false))
      .catch((error) => {
        setIsSubmitting(false);
        if (error) {
          setHasSubmitError(true);
        }
      });
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={onOpenChange}
      primaryAction={
        <S.CreateTeamFormSubmitButton
          form={formId}
          loading={isSubmitting}
          type="submit"
        >
          Create team
        </S.CreateTeamFormSubmitButton>
      }
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
      size="medium"
      title="Create new team"
    >
      <CreateTeamForm
        ref={formRef}
        id={formId}
        defaultValues={{ ageGroup: maxDivisionAgeGroup, country: 'USA' }}
        maxDivisionAgeGroup={maxDivisionAgeGroup}
        hasSubmitError={hasSubmitError}
        displayAgeGroupOptions={displayAgeGroupOptions}
        onSubmit={onSubmit}
        customFields={customFields}
      />
    </Modal>
  );
}
export const DivisionCard = forwardRef<HTMLDivElement, DivisionCardProps>(
  (props, ref?) => {
    const { division, showDates, showLocation } = props;

    const {
      id: divisionId,
      name,
      ageGroup,
      gender,
      cost,
      experienceLevel,
      location,
      startDate,
      endDate,
      registrationStartDate,
      registrationEndDate,
      displayAgeGroupOption,
    } = division;
    const cardId = getDivisionCardId(division);

    const cardLabelId = `${cardId}-label`;

    const { teamSelectionVisualUpdates = true, customTeamFormFields = true } =
      useFlags();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [updateTeam] = useUpdateTeamMutation();
    const { subdomain } = getLAHostnameParts();
    const siteId = useAppSelector(getSiteId);
    const userId = useAppSelector(getUserId);

    const handleFieldsUpdate = async (values: any) => {
      for (const teamId of Object.keys(values)) {
        const team = teamsList.find((team) => team.id === teamId);
        if (!team) {
          continue;
        }

        const formattedFields = await uploadCustomFileField({
          fields: values[teamId],
          siteId,
          userId,
        });

        const updatedFormFields = formattedFields.map(mapFormFieldToWorkflow);
        const allFormFields = removeDuplicateFormFields(
          updatedFormFields.concat(team.formFields ?? [])
        );

        const formattedTeam: Team = {
          ...team,
          metadata: {
            ...team.metadata,
            version: '1.0',
            teamRepresentatives: team.teamRepresentative
              ? [team.teamRepresentative]
              : undefined,
            ageGroup: team.ageGroup,
            formFields: allFormFields,
          },
        };

        updateTeam({
          siteDomain: subdomain,
          programId: divisionId,
          team: formattedTeam,
        })
          .unwrap()
          .then((updatedTeam) => {
            setIsEditModalOpen(false);
            onUpdateTeam(updatedTeam.id, updatedTeam.ageGroup);
          });
      }
    };

    /*
     * Empty string means that no dialog is open
     */
    const [dialogOpen, setDialogOpen] = useState<DialogOpen>('');
    /**
     * Index of the select that triggered the `CreateTeamDialog`. Used to
     * populate the correct select with the newly created team.
     */
    const [currentSelectIndex, setCurrentSelectIndex] = useState<number>(0);

    const [showUnsavedDivisionError, setShowUnsavedDivisionError] =
      useState(false);

    const selectRef = useRef<HTMLButtonElement>(null);

    const openCreateTeamDialog = () => {
      /*
       * When the dialog is opened via the "Enter" key, it will close immediately without this
       * setTimeout hack. I think the problem has something to do with event bubbling but I am
       * not sure. Unfortunately, the Select option that is being used to trigger the modal
       * opening doesn't give access to the event object to allow me to stop propagation.
       */
      setTimeout(() => {
        setDialogOpen('create-team');
      }, 0);
    };
    const openDiscardConfirmationDialog = () =>
      setDialogOpen('discard-confirmation');
    const closeDialog = () => setDialogOpen('');

    const handleOpenCreateTeamDialog = (selectIndex: number): void => {
      openCreateTeamDialog();
      setCurrentSelectIndex(selectIndex);
    };

    // Single day event uses label "Date"
    const datesLabel = startDate === endDate ? 'Date' : 'Dates';
    const dates = formatDateRange(
      DateTime.fromISO(startDate),
      endDate ? DateTime.fromISO(endDate) : undefined
    );

    const isTabletLandscapeUp = useMediaQuery(
      breakpointQueries.tabletLandscapeUp
    );
    const variant: CardProps['variant'] = isTabletLandscapeUp
      ? 'regular'
      : 'dense';

    // Associate the division name as a label for all interactive content within the card.
    const cardProps = {
      'aria-labelledby': cardLabelId,
      role: 'group',
    };

    const header = (
      <DivisionCardHeader
        cardLabelId={cardLabelId}
        cardState={props.cardState}
        cost={cost}
        name={name}
        numberOfSpotsLeft={getNumberOfSpotsLeft(division)}
        registrationEndDate={registrationEndDate}
        registrationStartDate={registrationStartDate}
        variant={variant}
      />
    );

    const isUnavailableDivision = (
      props: DivisionCardProps
    ): props is UnavailableDivisionCardProps => {
      const { cardState } = props;
      return cardState.value === 'unavailable';
    };

    let info;
    const infoProps = {
      ageGroup: ageGroup ? ageGroupDisplay(ageGroup) : undefined,
      datesValue: showDates ? dates : undefined,
      datesLabel,
      endDate,
      experienceLevel,
      gender,
      location: showLocation ? location : undefined,
      startDate,
      variant,
    };

    if (
      isUnavailableDivision(props) ||
      props.cardState.value === 'unavailable'
    ) {
      info = <DivisionCardInfoSection {...infoProps} rightSection={null} />;
    } else {
      const { cardState, onEditDivision, onSelectDivision } = props;
      info = (
        <DivisionCardInfoSection
          {...infoProps}
          rightSection={
            <DivisionCardInfoRightSection
              cardState={cardState}
              onEditDivision={onEditDivision}
              onSelectDivision={onSelectDivision}
            />
          }
        />
      );
    }

    const headerAndInfo = (
      <>
        {header}
        {info}
      </>
    );

    if (isUnavailableDivision(props)) {
      return (
        <S.UnavailableDivisionCard data-testid={cardId} {...cardProps}>
          <Card>{headerAndInfo}</Card>
        </S.UnavailableDivisionCard>
      );
    }

    const {
      availableTeams,
      cardState,
      divisionCardsState,
      maxDivisionAgeGroup,
      onAddTeamSelect,
      onClear,
      onCancel,
      onDeleteTeam,
      onDiscardTeamSelect,
      onSave,
      onSelectTeam,
      onTeamCreate,
      onUpdateTeam,
      tournamentId,
      setHasDeleteTeamDivisionError,
    } = props;

    if (cardState.value === 'not-selected' || cardState.value === 'saved') {
      return (
        <div data-testid={cardId} {...cardProps}>
          <Card variant={variant}>{headerAndInfo}</Card>
        </div>
      );
    }

    const focusTeamSelect = (): void => {
      /**
       * Since the trigger for the modal is the "+ Create new team" option,
       * which is not shown once the dialog is open, focus would normally return
       * to the body. Thus, we need to use setTimeout in order to queue
       * the focus() call AFTER it focuses the body.
       */
      setTimeout(() => {
        const select = document.getElementById(
          getTeamSelectId(divisionId, currentSelectIndex)
        );
        select?.focus();
      }, 0);
    };

    const handleOpenCreateTeamDialogChange = (): void => {
      closeDialog();
      focusTeamSelect();
    };

    const handleTeamCreate = async (
      team: CreateTeamFormFields
    ): Promise<void> => {
      const {
        ageGroup,
        province,
        repName,
        repPhoneNumber,
        repEmail,
        teamName,
        state,
        customTeamFields,
        ...restTeam
      } = team;

      const newTeam: Omit<Team, 'id' | 'ageGroup'> = {
        ...restTeam,
        name: teamName,
        admin1: province || state,
        status: 'DRAFT',
        metadata: {
          version: '1.0',
          ageGroup,
          teamRepresentatives:
            repName && repPhoneNumber && repEmail
              ? [
                  {
                    name: repName,
                    phoneNumber: repPhoneNumber,
                    email: repEmail,
                  },
                ]
              : undefined,
          formFields: customTeamFields.map(mapFormFieldToWorkflow),
        },
      };

      if (teamSelectionVisualUpdates) {
        onAddTeamSelect();
      }

      return onTeamCreate(divisionId, currentSelectIndex, newTeam).then(() => {
        closeDialog();
        focusTeamSelect();
      });
    };

    const teamsOptions = availableTeams.filter((team) => {
      if (division.ageGroup) {
        return division.ageGroup >= team.ageGroup;
      }
      return true;
    });
    const teamsList = getSortedTeamsList(teamsOptions);

    let errorMessage;
    if (
      cardState.value !== 'unavailable' &&
      cardState.isShowingSubmissionErrorMessage
    ) {
      errorMessage = DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE;
    }

    const handleSaveMultipleTeams = () => {
      if (
        cardState.value === 'not-saved' ||
        cardState.value === 'saved-and-editing'
      ) {
        // This is a temporary workaround to check if there are selected teams in the children component without triggering a re-render
        // once we remove the feature flag we can just lift the state up to Wizard and check from there. If we do this right now the amount of checks
        // and changes will be just too big.
        const hasUnsavedTeams = selectRef.current?.querySelectorAll(
          '[data-testid="multi-select-value-container"]'
        ).length;

        if (
          !cardState.teamSelections.some(
            (selection) => selection.teamId !== ''
          ) ||
          hasUnsavedTeams
        ) {
          setShowUnsavedDivisionError(true);
          return null;
        }
        setShowUnsavedDivisionError(false);
        onSave();
      }
    };

    const selectedTeams =
      cardState.value === 'not-saved' || cardState.value === 'saved-and-editing'
        ? cardState.teamSelections
            .map(({ teamId }) => {
              return teamsList.find(({ id }) => id === teamId);
            })
            .filter((team): team is Team => !!team)
        : [];

    const incompleteTeams = getIncompleteRequiredFieldsTeams(
      division.customTeamFields,
      selectedTeams
    );
    const hasIncompleteFields = incompleteTeams.length > 0;

    return (
      <div data-testid={cardId} {...cardProps}>
        <Card
          hasError={!!errorMessage}
          errorMessage={errorMessage}
          ref={ref}
          variant={variant}
        >
          {headerAndInfo}
          {cardState.value === 'not-saved' ||
          cardState.value === 'saved-and-editing' ? (
            <>
              {customTeamFormFields &&
              hasIncompleteFields &&
              isEditModalOpen ? (
                <EditTeamFieldsModal
                  teams={teamsList}
                  addedTeamsIds={selectedTeams.map((team) => team.id)}
                  open={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  customFields={division.customTeamFields}
                  handleFieldsUpdate={handleFieldsUpdate}
                />
              ) : null}
              {teamSelectionVisualUpdates ? (
                <TeamSelectionSection
                  teams={teamsList}
                  openCreateTeamDialog={handleOpenCreateTeamDialog}
                  onSelectTeam={onSelectTeam}
                  cardState={cardState}
                  division={division}
                  divisionCardsState={divisionCardsState}
                  maxDivisionAgeGroup={maxDivisionAgeGroup}
                  onClear={onClear}
                  onDeleteTeam={onDeleteTeam}
                  onDiscardTeamSelect={onDiscardTeamSelect}
                  onUpdateTeam={onUpdateTeam}
                  openDiscardConfirmationDialog={openDiscardConfirmationDialog}
                  setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
                  teamsList={teamsList}
                  tournamentId={tournamentId}
                  onAddTeamSelect={onAddTeamSelect}
                  selectError={showUnsavedDivisionError}
                  setSelectError={() => setShowUnsavedDivisionError(false)}
                  selectRef={selectRef}
                />
              ) : (
                <DivisionCardTeamSelectionSection
                  cardId={cardId}
                  cardState={cardState}
                  division={division}
                  divisionCardsState={divisionCardsState}
                  maxDivisionAgeGroup={maxDivisionAgeGroup}
                  openCreateTeamDialog={handleOpenCreateTeamDialog}
                  openDiscardConfirmationDialog={openDiscardConfirmationDialog}
                  onAddTeamSelect={onAddTeamSelect}
                  onClear={onClear}
                  onDeleteTeam={onDeleteTeam}
                  onDiscardTeamSelect={onDiscardTeamSelect}
                  onSelectTeam={onSelectTeam}
                  onUpdateTeam={onUpdateTeam}
                  teamsList={teamsList}
                  tournamentId={tournamentId}
                  variant={variant}
                  setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
                />
              )}
              <DivisionCardFooter
                onCancel={onCancel}
                onSave={() => {
                  if (teamSelectionVisualUpdates) {
                    if (hasIncompleteFields) {
                      setIsEditModalOpen(true);
                    } else {
                      handleSaveMultipleTeams();
                    }
                  } else {
                    onSave();
                  }
                }}
                shouldUpdateFields={hasIncompleteFields}
              />
            </>
          ) : null}
          <DiscardConfirmationDialog
            isOpen={dialogOpen === 'discard-confirmation'}
            onOpenChange={closeDialog}
            onClearDivision={onClear}
            close={closeDialog}
          />
          <CreateTeamDialog
            displayAgeGroupOptions={displayAgeGroupOption}
            isOpen={dialogOpen === 'create-team'}
            maxDivisionAgeGroup={maxDivisionAgeGroup}
            onOpenChange={handleOpenCreateTeamDialogChange}
            onTeamCreate={handleTeamCreate}
            customFields={division.customTeamFields}
          />
        </Card>
      </div>
    );
  }
);

/**
 * Returns an array containing the teams sorted first by age group (oldest
 * to youngest) and then alphabetically by name.
 * @param allTeams
 */
export function getSortedTeamsList(allTeams: Team[]) {
  return [...allTeams].sort((a, b) => {
    if (a.ageGroup > b.ageGroup) {
      return -1;
    } else if (a.ageGroup < b.ageGroup) {
      return 1;
    }
    return a.name.localeCompare(b.name, 'en');
  });
}

/**
 * @param ageGroup The age group for a program or division
 * @returns A string to display for age group
 */
export function ageGroupDisplay(ageGroup: number) {
  return `${ageGroup}u`;
}

export function getDivisionCardId(division: Division) {
  return `reg-wizard-division-card-${division.id}`;
}
