var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Modal, ModalClose, ModalSecondaryActionButton, ModalTertiaryActionButton, PencilSquareIcon, Typography, } from '@la/ds-ui-components';
import { useDeleteTeamMutation, useUpdateTeamMutation } from '@la/services';
import { getSiteIdFromURL, mapFormFieldToWorkflow, removeDuplicateFormFields, } from '@la/utilities';
import { ErrorCard } from '../../../../../../components/ErrorCard/ErrorCard';
import { CreateTeamForm, } from '../../CreateTeamForm/CreateTeamForm';
import * as S from './UpdateTeamDialog.styles';
export const UPDATE_TEAM_ERROR_MESSAGE = 'There was an error updating your team. Please try updating again in a few seconds.';
export const DELETE_TEAM_ERROR_MESSAGE = 'There was an error deleting your team. Please try deleting again in a few seconds.';
export function UpdateTeamDialog({ divisionId, maxDivisionAgeGroup, hasDivisionsWithTeamSelected, setHasDeleteTeamDivisionError, onDeleteTeam, onUpdateTeam, team, tournamentId, trigger, customFields, }) {
    const [updateTeam, { isLoading: isUpdateTeamLoading, error: updateTeamError, reset },] = useUpdateTeamMutation();
    const siteId = getSiteIdFromURL();
    const [isOpen, setIsOpen] = useState();
    const { admin1, country, name, status, teamRepresentative } = team;
    const teamFormId = 'update-team-form';
    let defaultLocationValues;
    if (country === 'USA' && admin1) {
        defaultLocationValues = { country, state: admin1 };
    }
    else if (country === 'CAN' && admin1) {
        defaultLocationValues = { country, province: admin1 };
    }
    else {
        defaultLocationValues = { country };
    }
    const defaultValues = Object.assign(Object.assign({}, defaultLocationValues), { teamName: name, organization: team.organization, ageGroup: team.ageGroup, city: team.city, repName: teamRepresentative === null || teamRepresentative === void 0 ? void 0 : teamRepresentative.name, repPhoneNumber: teamRepresentative === null || teamRepresentative === void 0 ? void 0 : teamRepresentative.phoneNumber, repEmail: teamRepresentative === null || teamRepresentative === void 0 ? void 0 : teamRepresentative.email, customTeamFields: customFields });
    /**
     * Resets the query state so that errors do not show when
     * reopening the modal.
     */
    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);
    const handleUpdateTeam = (formTeam) => {
        var _a, _b;
        const { teamName, state, province, repName, repPhoneNumber, repEmail, customTeamFields } = formTeam, restTeam = __rest(formTeam, ["teamName", "state", "province", "repName", "repPhoneNumber", "repEmail", "customTeamFields"]);
        const updatedFormFields = customTeamFields.map(mapFormFieldToWorkflow);
        const allFormFields = removeDuplicateFormFields(updatedFormFields.concat((_a = team.formFields) !== null && _a !== void 0 ? _a : []));
        const formattedTeam = Object.assign(Object.assign({}, restTeam), { id: team.id, name: teamName, admin1: (_b = state !== null && state !== void 0 ? state : province) !== null && _b !== void 0 ? _b : '', status: team.status, metadata: {
                version: '1.0',
                teamRepresentatives: repName && repPhoneNumber && repEmail
                    ? [{ name: repName, phoneNumber: repPhoneNumber, email: repEmail }]
                    : undefined,
                ageGroup: restTeam.ageGroup,
                formFields: allFormFields,
            } });
        updateTeam({
            siteDomain: 'manager',
            programId: divisionId,
            team: formattedTeam,
            siteId: siteId.toString(),
        })
            .unwrap()
            .then((updatedTeam) => {
            setIsOpen(false);
            onUpdateTeam(updatedTeam.id, updatedTeam.ageGroup, true);
        });
    };
    const handleDeleteTeam = (deletedTeamId) => {
        setIsOpen(false);
        onDeleteTeam(deletedTeamId, true);
    };
    return (_jsxs(Modal, Object.assign({ open: isOpen, onOpenChange: setIsOpen, primaryAction: _jsx(S.UpdateTeamButton, Object.assign({ form: teamFormId, loading: isUpdateTeamLoading, type: "submit" }, { children: "Update team" })), secondaryAction: _jsx(DeleteTeamDialog, { hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, onDeleteTeam: handleDeleteTeam, team: team, tournamentId: tournamentId, closeUpdateModal: () => setIsOpen(false) }), tertiaryAction: _jsx(ModalClose, { children: _jsx(ModalTertiaryActionButton, { children: "Cancel" }) }), title: "Update team", trigger: trigger ? (trigger) : (_jsx(S.UpdateTeamTriggerButton, { ariaLabel: `Update team ${name}`, disabled: status !== 'DRAFT', variant: "text", icon: _jsx(PencilSquareIcon, { fill: "red", size: "large" }) })) }, { children: [updateTeamError ? (_jsx(ErrorCard, { message: UPDATE_TEAM_ERROR_MESSAGE })) : null, _jsx(CreateTeamForm, { defaultValues: defaultValues, id: teamFormId, maxDivisionAgeGroup: maxDivisionAgeGroup, onSubmit: handleUpdateTeam, customFields: customFields, existingFields: team.formFields })] })));
}
function DeleteTeamDialog({ hasDivisionsWithTeamSelected, onDeleteTeam, team, tournamentId, setHasDeleteTeamDivisionError, closeUpdateModal, }) {
    const [isOpen, setIsOpen] = useState();
    const [deleteTeam, { isLoading: isDeleteTeamLoading, error: deleteTeamError, reset },] = useDeleteTeamMutation();
    const siteId = getSiteIdFromURL();
    /**
     * Resets the query state so that errors do not show when
     * reopening the modal.
     */
    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);
    const handleDeleteTeam = () => {
        if (hasDivisionsWithTeamSelected) {
            setHasDeleteTeamDivisionError(true);
            setIsOpen(false);
            closeUpdateModal();
            return;
        }
        const { id } = team;
        deleteTeam({
            siteDomain: 'manager',
            siteId: siteId.toString(),
            programId: tournamentId,
            editURL: `/app/tournaments/${tournamentId}/registration`,
            teamId: id,
        })
            .unwrap()
            .then(() => {
            setIsOpen(false);
            onDeleteTeam(id);
        });
    };
    return (_jsxs(Modal, Object.assign({ open: isOpen, onOpenChange: setIsOpen, title: "Delete team?", primaryAction: _jsx(S.DeleteTeamButton, Object.assign({ loading: isDeleteTeamLoading, onClick: handleDeleteTeam }, { children: "Delete team" })), tertiaryAction: _jsx(ModalClose, { children: _jsx(ModalSecondaryActionButton, { children: "Cancel" }) }), trigger: _jsx(ModalSecondaryActionButton, { children: "Delete team" }) }, { children: [deleteTeamError ? (_jsx(ErrorCard, { message: DELETE_TEAM_ERROR_MESSAGE })) : null, _jsx(Typography, Object.assign({ size: "large", variant: "ui" }, { children: "Are you sure you want to delete this team altogether?" }))] })));
}
