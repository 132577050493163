var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { Card, Modal, ModalClose, ModalPrimaryActionButton, ModalTertiaryActionButton, } from '@la/ds-ui-components';
import { useUpdateTeamMutation } from '@la/services';
import { formatDateRange, getSiteIdFromURL, mapFormFieldToWorkflow, removeDuplicateFormFields, } from '@la/utilities';
import { EditTeamFieldsModal } from '../../../../../components/EditTeamFieldsModal/EditTeamFieldsModal';
import { TeamSelectionSection } from '../../../../../components/TeamSelectionSection/TeamSelectionSection';
import { getIncompleteRequiredFieldsTeams } from '../../../../../components/TeamSelectionSection/utils/validation';
import { useMediaQuery } from '../../../../../lib/hooks';
import { breakpointQueries } from '../../../../../lib/media-queries/breakpoints';
import { CreateTeamForm, } from '../CreateTeamForm/CreateTeamForm';
import { DivisionCardFooter } from './DivisionCardFooter/DivisionCardFooter';
import { DivisionCardHeader } from './DivisionCardHeader/DivisionCardHeader';
import { DivisionCardInfoSection } from './DivisionCardInfoSection/DivisionCardInfoSection';
import { DivisionCardInfoRightSection } from './DivisionCardInfoSectionRight/DivisionCardInfoSectionRight';
import { DivisionCardTeamSelectionSection, getTeamSelectId, } from './DivisionCardTeamSelectionSection/DivisionCardTeamSelectionSection';
import { getNumberOfSpotsLeft } from './utils/capacity';
import * as S from './DivisionCard.styles';
export const teamFields = ['name', 'organization'];
export const DISCARD_CONFIRMATION_DIALOG_TITLE = 'Remove team';
export const DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT = 'Yes, remove this';
export const DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE = 'Select "Save division" to save your current changes or "Cancel" to discard them.';
function DiscardConfirmationDialog({ isOpen, onOpenChange, onClearDivision, close, }) {
    const handleConfirmation = () => {
        onClearDivision();
        close();
    };
    return (_jsxs(Modal, Object.assign({ open: isOpen, onOpenChange: onOpenChange, primaryAction: _jsx(ModalPrimaryActionButton, Object.assign({ onClick: handleConfirmation }, { children: DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT })), tertiaryAction: _jsx(ModalClose, { children: _jsx(ModalTertiaryActionButton, { children: "Cancel" }) }), size: "medium", title: DISCARD_CONFIRMATION_DIALOG_TITLE }, { children: ["You are now removing this team and division from your registrations.", _jsx(S.ConfirmationSentence, { children: "Are you sure you want to continue?" })] })));
}
function CreateTeamDialog({ maxDivisionAgeGroup, displayAgeGroupOptions = true, isOpen, onOpenChange, onTeamCreate, customFields, }) {
    const formRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const formId = 'create-team-form';
    /**
     * When a submission error occurs, the error is shown at the top of
     * the form in the modal's body. Beause the content of the body is
     * scrollable, we want to scroll the user to the top so they can
     * see it immediately.
     */
    useEffect(() => {
        const form = formRef.current;
        if (hasSubmitError && form && form.parentNode) {
            const formParentNode = form.parentNode;
            if (!!formParentNode.scrollTo) {
                formParentNode.scrollTo({
                    top: 0,
                });
            }
        }
    }, [hasSubmitError]);
    const onSubmit = (team) => {
        setHasSubmitError(false);
        setIsSubmitting(true);
        onTeamCreate(team)
            .then(() => setIsSubmitting(false))
            .catch((error) => {
            setIsSubmitting(false);
            if (error) {
                setHasSubmitError(true);
            }
        });
    };
    return (_jsx(Modal, Object.assign({ open: isOpen, onOpenChange: onOpenChange, primaryAction: _jsx(S.CreateTeamFormSubmitButton, Object.assign({ form: formId, loading: isSubmitting, type: "submit" }, { children: "Create team" })), tertiaryAction: _jsx(ModalClose, { children: _jsx(ModalTertiaryActionButton, { children: "Cancel" }) }), size: "medium", title: "Create new team" }, { children: _jsx(CreateTeamForm, { ref: formRef, id: formId, defaultValues: { ageGroup: maxDivisionAgeGroup, country: 'USA' }, maxDivisionAgeGroup: maxDivisionAgeGroup, hasSubmitError: hasSubmitError, displayAgeGroupOptions: displayAgeGroupOptions, onSubmit: onSubmit, customFields: customFields }) })));
}
export const DivisionCard = forwardRef((props, ref) => {
    const { division, showDates, showLocation } = props;
    const { id: divisionId, name, ageGroup, gender, cost, experienceLevel, location, startDate, endDate, registrationStartDate, registrationEndDate, displayAgeGroupOption, } = division;
    const cardId = getDivisionCardId(division);
    const cardLabelId = `${cardId}-label`;
    const { mcTeamSelectionVisualUpdates = true } = useFlags();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [updateTeam] = useUpdateTeamMutation();
    const siteId = getSiteIdFromURL();
    const handleFieldsUpdate = (values) => {
        var _a;
        for (const teamId of Object.keys(values)) {
            const team = teamsList.find((team) => team.id === teamId);
            if (!team) {
                continue;
            }
            const updatedFormFields = values[teamId].map(mapFormFieldToWorkflow);
            const allFormFields = removeDuplicateFormFields(updatedFormFields.concat((_a = team.formFields) !== null && _a !== void 0 ? _a : []));
            const formattedTeam = Object.assign(Object.assign({}, team), { metadata: Object.assign(Object.assign({}, team.metadata), { version: '1.0', teamRepresentatives: team.teamRepresentative
                        ? [team.teamRepresentative]
                        : undefined, ageGroup: team.ageGroup, formFields: allFormFields }) });
            updateTeam({
                siteDomain: 'manager',
                programId: divisionId,
                team: formattedTeam,
                siteId: siteId.toString(),
            })
                .unwrap()
                .then((updatedTeam) => {
                setIsEditModalOpen(false);
                onUpdateTeam(updatedTeam.id, updatedTeam.ageGroup, true);
            });
        }
    };
    /*
     * Empty string means that no dialog is open
     */
    const [dialogOpen, setDialogOpen] = useState('');
    /**
     * Index of the select that triggered the `CreateTeamDialog`. Used to
     * populate the correct select with the newly created team.
     */
    const [currentSelectIndex, setCurrentSelectIndex] = useState(0);
    const [showUnsavedDivisionError, setShowUnsavedDivisionError] = useState(false);
    const selectRef = useRef(null);
    const openCreateTeamDialog = () => {
        /*
         * When the dialog is opened via the "Enter" key, it will close immediately without this
         * setTimeout hack. I think the problem has something to do with event bubbling but I am
         * not sure. Unfortunately, the Select option that is being used to trigger the modal
         * opening doesn't give access to the event object to allow me to stop propagation.
         */
        setTimeout(() => {
            setDialogOpen('create-team');
        }, 0);
    };
    const openDiscardConfirmationDialog = () => setDialogOpen('discard-confirmation');
    const closeDialog = () => setDialogOpen('');
    const handleOpenCreateTeamDialog = (selectIndex) => {
        openCreateTeamDialog();
        setCurrentSelectIndex(selectIndex);
    };
    // Single day event uses label "Date"
    const datesLabel = startDate === endDate ? 'Date' : 'Dates';
    const dates = formatDateRange(DateTime.fromISO(startDate), endDate ? DateTime.fromISO(endDate) : undefined);
    const isTabletLandscapeUp = useMediaQuery(breakpointQueries.tabletLandscapeUp);
    const variant = isTabletLandscapeUp
        ? 'regular'
        : 'dense';
    // Associate the division name as a label for all interactive content within the card.
    const cardProps = {
        'aria-labelledby': cardLabelId,
        role: 'group',
    };
    const header = (_jsx(DivisionCardHeader, { cardLabelId: cardLabelId, cardState: props.cardState, cost: cost, name: name, numberOfSpotsLeft: getNumberOfSpotsLeft(division), registrationEndDate: registrationEndDate, registrationStartDate: registrationStartDate, variant: variant }));
    const isUnavailableDivision = (props) => {
        const { cardState } = props;
        return cardState.value === 'unavailable';
    };
    let info;
    const infoProps = {
        ageGroup: ageGroup ? ageGroupDisplay(ageGroup) : undefined,
        datesValue: showDates ? dates : undefined,
        datesLabel,
        endDate,
        experienceLevel,
        gender,
        location: showLocation ? location : undefined,
        startDate,
        variant,
    };
    if (isUnavailableDivision(props) ||
        props.cardState.value === 'unavailable') {
        info = _jsx(DivisionCardInfoSection, Object.assign({}, infoProps, { rightSection: null }));
    }
    else {
        const { cardState, onEditDivision, onSelectDivision } = props;
        info = (_jsx(DivisionCardInfoSection, Object.assign({}, infoProps, { rightSection: _jsx(DivisionCardInfoRightSection, { cardState: cardState, onEditDivision: onEditDivision, onSelectDivision: onSelectDivision }) })));
    }
    const headerAndInfo = (_jsxs(_Fragment, { children: [header, info] }));
    if (isUnavailableDivision(props)) {
        return (_jsx(S.UnavailableDivisionCard, Object.assign({ "data-testid": cardId }, cardProps, { children: _jsx(Card, { children: headerAndInfo }) })));
    }
    const { availableTeams, cardState, divisionCardsState, maxDivisionAgeGroup, onAddTeamSelect, onClear, onCancel, onDeleteTeam, onDiscardTeamSelect, onSave, onSelectTeam, onTeamCreate, onUpdateTeam, tournamentId, setHasDeleteTeamDivisionError, } = props;
    if (cardState.value === 'not-selected' || cardState.value === 'saved') {
        return (_jsx(S.DivisionCard, Object.assign({ "data-testid": cardId }, cardProps, { children: _jsx(Card, Object.assign({ variant: variant }, { children: headerAndInfo })) })));
    }
    const focusTeamSelect = () => {
        /**
         * Since the trigger for the modal is the "+ Create new team" option,
         * which is not shown once the dialog is open, focus would normally return
         * to the body. Thus, we need to use setTimeout in order to queue
         * the focus() call AFTER it focuses the body.
         */
        setTimeout(() => {
            const select = document.getElementById(getTeamSelectId(divisionId, currentSelectIndex));
            select === null || select === void 0 ? void 0 : select.focus();
        }, 0);
    };
    const handleOpenCreateTeamDialogChange = () => {
        closeDialog();
        focusTeamSelect();
    };
    const handleTeamCreate = (team) => __awaiter(void 0, void 0, void 0, function* () {
        const { ageGroup, province, repName, repPhoneNumber, repEmail, teamName, state, customTeamFields } = team, restTeam = __rest(team, ["ageGroup", "province", "repName", "repPhoneNumber", "repEmail", "teamName", "state", "customTeamFields"]);
        const newTeam = Object.assign(Object.assign({}, restTeam), { name: teamName, admin1: province || state, status: 'DRAFT', metadata: {
                version: '1.0',
                ageGroup,
                teamRepresentatives: repName && repPhoneNumber && repEmail
                    ? [
                        {
                            name: repName,
                            phoneNumber: repPhoneNumber,
                            email: repEmail,
                        },
                    ]
                    : undefined,
                formFields: customTeamFields.map(mapFormFieldToWorkflow),
            } });
        if (mcTeamSelectionVisualUpdates) {
            onAddTeamSelect();
        }
        return onTeamCreate(divisionId, currentSelectIndex, newTeam).then(() => {
            closeDialog();
            focusTeamSelect();
        });
    });
    const teamsOptions = availableTeams.filter((team) => {
        if (division.ageGroup) {
            return division.ageGroup >= team.ageGroup;
        }
        return true;
    });
    const teamsList = getSortedTeamsList(teamsOptions);
    let errorMessage;
    if (cardState.value !== 'unavailable' &&
        cardState.isShowingSubmissionErrorMessage) {
        errorMessage = DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE;
    }
    const handleSaveMultipleTeams = () => {
        var _a;
        if (cardState.value === 'not-saved' ||
            cardState.value === 'saved-and-editing') {
            // This is a temporary workaround to check if there are selected teams in the children component without triggering a re-render
            // once we remove the feature flag we can just lift the state up to Wizard and check from there. If we do this right now the amount of checks
            // and changes will be just too big.
            const hasUnsavedTeams = (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('[data-testid="multi-select-value-container"]').length;
            if (!cardState.teamSelections.some((selection) => selection.teamId !== '') ||
                hasUnsavedTeams) {
                setShowUnsavedDivisionError(true);
                return null;
            }
            setShowUnsavedDivisionError(false);
            onSave();
        }
    };
    const selectedTeams = cardState.value === 'not-saved' || cardState.value === 'saved-and-editing'
        ? cardState.teamSelections
            .map(({ teamId }) => {
            return teamsList.find(({ id }) => id === teamId);
        })
            .filter((team) => !!team)
        : [];
    const incompleteTeams = getIncompleteRequiredFieldsTeams(division.customTeamFields, selectedTeams);
    const hasIncompleteFields = incompleteTeams.length > 0;
    return (_jsx(S.DivisionCard, Object.assign({ "data-testid": cardId }, cardProps, { children: _jsxs(Card, Object.assign({ hasError: !!errorMessage, errorMessage: errorMessage, ref: ref, variant: variant }, { children: [headerAndInfo, cardState.value === 'not-saved' ||
                    cardState.value === 'saved-and-editing' ? (_jsxs(_Fragment, { children: [hasIncompleteFields && isEditModalOpen ? (_jsx(EditTeamFieldsModal, { teams: teamsList, addedTeamsIds: selectedTeams.map((team) => team.id), open: isEditModalOpen, onClose: () => setIsEditModalOpen(false), customFields: division.customTeamFields, handleFieldsUpdate: handleFieldsUpdate })) : null, mcTeamSelectionVisualUpdates ? (_jsx(TeamSelectionSection, { teams: teamsList, openCreateTeamDialog: handleOpenCreateTeamDialog, onSelectTeam: onSelectTeam, cardState: cardState, division: division, divisionCardsState: divisionCardsState, maxDivisionAgeGroup: maxDivisionAgeGroup, onClear: onClear, onDeleteTeam: onDeleteTeam, onDiscardTeamSelect: onDiscardTeamSelect, onUpdateTeam: onUpdateTeam, openDiscardConfirmationDialog: openDiscardConfirmationDialog, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, teamsList: teamsList, tournamentId: tournamentId, onAddTeamSelect: onAddTeamSelect, selectError: showUnsavedDivisionError, setSelectError: () => setShowUnsavedDivisionError(false), selectRef: selectRef })) : (_jsx(DivisionCardTeamSelectionSection, { cardId: cardId, cardState: cardState, division: division, divisionCardsState: divisionCardsState, maxDivisionAgeGroup: maxDivisionAgeGroup, openCreateTeamDialog: handleOpenCreateTeamDialog, openDiscardConfirmationDialog: openDiscardConfirmationDialog, onAddTeamSelect: onAddTeamSelect, onClear: onClear, onDeleteTeam: onDeleteTeam, onDiscardTeamSelect: onDiscardTeamSelect, onSelectTeam: onSelectTeam, teamsList: teamsList, tournamentId: tournamentId, variant: variant, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, onUpdateTeam: onUpdateTeam })), _jsx(DivisionCardFooter, { onCancel: onCancel, onSave: () => {
                                if (mcTeamSelectionVisualUpdates) {
                                    if (hasIncompleteFields) {
                                        setIsEditModalOpen(true);
                                    }
                                    else {
                                        handleSaveMultipleTeams();
                                    }
                                }
                                else {
                                    onSave();
                                }
                            }, shouldUpdateFields: hasIncompleteFields })] })) : null, _jsx(DiscardConfirmationDialog, { isOpen: dialogOpen === 'discard-confirmation', onOpenChange: closeDialog, onClearDivision: onClear, close: closeDialog }), _jsx(CreateTeamDialog, { displayAgeGroupOptions: displayAgeGroupOption, isOpen: dialogOpen === 'create-team', maxDivisionAgeGroup: maxDivisionAgeGroup, onOpenChange: handleOpenCreateTeamDialogChange, onTeamCreate: handleTeamCreate, customFields: division.customTeamFields })] })) })));
});
/**
 * Returns an array containing the teams sorted first by age group (oldest
 * to youngest) and then alphabetically by name.
 * @param allTeams
 */
export function getSortedTeamsList(allTeams) {
    return [...allTeams].sort((a, b) => {
        if (a.ageGroup > b.ageGroup) {
            return -1;
        }
        else if (a.ageGroup < b.ageGroup) {
            return 1;
        }
        return a.name.localeCompare(b.name, 'en');
    });
}
/**
 * @param ageGroup The age group for a program or division
 * @returns A string to display for age group
 */
export function ageGroupDisplay(ageGroup) {
    return `${ageGroup}u`;
}
export function getDivisionCardId(division) {
    return `reg-wizard-division-card-${division.id}`;
}
