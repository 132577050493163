import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Typography, PlusCircleIcon, TrashBinIcon, } from '@la/ds-ui-components';
import { useGetUserRegistrationsQuery } from '@la/services';
import { useMediaQuery } from '../../../../../../lib/hooks';
import { breakpointQueries } from '../../../../../../lib/media-queries/breakpoints';
import { TeamRegistrationContext } from '../../../../TeamRegistration/TeamRegistrationContext';
import { ageGroupDisplay } from '../DivisionCard';
import { getNumberOfSpotsLeft } from '../utils/capacity';
import { UpdateTeamDialog } from './UpdateTeamDialog';
import { DivisionCardSection } from '../DivisionCard.styles';
import * as S from './DivisionCardTeamSelectionSection.styles';
export const TEAM_SELECT_PLACEHOLDER_OPTION_TEXT = 'Select or create a team';
export const EMPTY_SELECT_ERROR_MESSAGE = 'Team not selected. Select a team or discard it.';
export const DIVISION_NOT_SAVED_ALERT_MESSAGE = 'Division not saved. One or more team selections were left blank.';
function DivisionCardTeamSelects({ cardState, division, maxDivisionAgeGroup, onClear, onDeleteTeam, onDiscardTeamSelect, onSelectTeam, openCreateTeamDialog, openDiscardConfirmationDialog, teamsList, tournamentId, setHasDeleteTeamDivisionError, onUpdateTeam, }) {
    var _a;
    const divisionId = division.id;
    const { siteId } = useParams();
    const { state: { payer }, } = useContext(TeamRegistrationContext);
    const { data } = useGetUserRegistrationsQuery({
        siteDomain: 'svssc',
        programId: division.id,
        payerId: payer ? payer.id.toString() : undefined,
        siteId,
    });
    const createNewTeamOption = {
        label: '+ Create new team',
        value: 'create-new-team',
    };
    const { teamSelections } = cardState;
    const registeredTeams = (_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.team.filter((registration) => registration.registrationStatus === 'REGISTERED').map((registration) => registration.teamId);
    return (_jsx(_Fragment, { children: teamSelections.map((teamSelection, selectIndex) => {
            var _a, _b;
            const { teamId, hasError } = teamSelection;
            const team = teamsList.find((team) => team.id === teamId);
            const selectOptions = [
                ...teamsList.map((team) => {
                    return {
                        label: getTeamOptionLabel(team, registeredTeams),
                        value: team.id,
                        disabled: (teamSelections.find((teamSelectionInner) => teamSelectionInner.teamId === team.id) &&
                            team.id !== teamId) ||
                            (registeredTeams && registeredTeams.includes(team.id)),
                    };
                }),
                createNewTeamOption,
            ];
            const onSelectChange = (newValue) => {
                if (newValue === createNewTeamOption.value) {
                    openCreateTeamDialog(selectIndex);
                }
                else {
                    onSelectTeam(selectIndex, newValue);
                }
            };
            const onDiscard = () => {
                if (teamSelections.length === 1) {
                    if (cardState.value === 'saved-and-editing') {
                        openDiscardConfirmationDialog();
                    }
                    else {
                        onClear();
                    }
                }
                else {
                    onDiscardTeamSelect(selectIndex);
                }
            };
            const selectId = getTeamSelectId(divisionId, selectIndex);
            const errorMessageId = `${selectId}-error-message`;
            const label = `Team ${selectIndex + 1}`;
            const teamName = (_a = selectOptions.find((option) => option.value === teamId)) === null || _a === void 0 ? void 0 : _a.label;
            const hasDivisionsWithTeamSelected = (_b = data === null || data === void 0 ? void 0 : data.userRegistrations.some((registration) => registration.teamId === teamId)) !== null && _b !== void 0 ? _b : false;
            return (_jsxs(S.DivisionCardTeamSelection, { children: [_jsxs(S.DivisionCardTeamSelectionTopRow, { children: [_jsx(Select, { id: selectId, label: label, options: selectOptions, placeholder: TEAM_SELECT_PLACEHOLDER_OPTION_TEXT, value: teamId, onChange: onSelectChange, hasError: hasError, "aria-describedby": errorMessageId }), team ? (_jsx(UpdateTeamDialog, { divisionId: divisionId, maxDivisionAgeGroup: maxDivisionAgeGroup, hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, onDeleteTeam: onDeleteTeam, onUpdateTeam: onUpdateTeam, team: team, tournamentId: tournamentId, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, customFields: division.customTeamFields })) : null, _jsx("span", Object.assign({ style: {
                                    cursor: 'pointer',
                                    marginBottom: '12px',
                                    marginLeft: '12px',
                                }, onClick: onDiscard, "aria-label": teamName
                                    ? `Discard team ${teamName}`
                                    : `Discard team ${selectIndex + 1}` }, { children: _jsx(TrashBinIcon, { fill: "var(--green-600)", variant: "regular", size: "medium" }) }))] }), hasError ? (_jsx(S.DivisionCardTeamSelectionErrorMessage, Object.assign({ id: errorMessageId, "data-testid": errorMessageId }, { children: EMPTY_SELECT_ERROR_MESSAGE }))) : null] }, selectIndex));
        }) }));
}
export function DivisionCardTeamSelectionSection({ cardId, cardState, division, divisionCardsState, maxDivisionAgeGroup, onAddTeamSelect, onClear, onDeleteTeam, onDiscardTeamSelect, onSelectTeam, openCreateTeamDialog, openDiscardConfirmationDialog, teamsList, tournamentId, variant, onUpdateTeam, setHasDeleteTeamDivisionError, }) {
    const addTeamSelectButtonId = `${cardId}-add-team-select`;
    const numberOfSpotsLeft = getNumberOfSpotsLeft(division);
    const isTabletPortraitUp = useMediaQuery(breakpointQueries.tabletLandscapeUp);
    return (_jsx(DivisionCardSection, Object.assign({ "$variant": variant }, { children: _jsxs(S.DivisionCardTeams, { children: [_jsx(Typography, Object.assign({ size: isTabletPortraitUp ? 'large' : 'medium', variant: "ui", weight: "bold" }, { children: "Add your teams" })), cardState.isShowingDivisionNotSavedMessage ? (_jsx(S.DivisionCardTeamSelectionAlertMessage, Object.assign({ role: "alert" }, { children: DIVISION_NOT_SAVED_ALERT_MESSAGE }))) : null, _jsx(DivisionCardTeamSelects, { onUpdateTeam: onUpdateTeam, cardState: cardState, division: division, divisionCardsState: divisionCardsState, maxDivisionAgeGroup: maxDivisionAgeGroup, onClear: onClear, onDeleteTeam: onDeleteTeam, onDiscardTeamSelect: onDiscardTeamSelect, onSelectTeam: onSelectTeam, openCreateTeamDialog: openCreateTeamDialog, openDiscardConfirmationDialog: openDiscardConfirmationDialog, teamsList: teamsList, tournamentId: tournamentId, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError }), !!numberOfSpotsLeft &&
                    cardState.teamSelections.length >= numberOfSpotsLeft ? (_jsx(S.DivisionSpotsLeftMessage, Object.assign({ size: "medium", variant: "ui", weight: "bold" }, { children: numberOfSpotsLeft <= 0
                        ? 'Division has no spots left'
                        : `Division only has ${numberOfSpotsLeft === 1 ? '1 spot' : `${numberOfSpotsLeft} spots`} left` }))) : (_jsx(S.DivisionCardAddTeamSelectButton, Object.assign({ id: addTeamSelectButtonId, "data-testid": addTeamSelectButtonId, variant: "text", leftIcon: _jsx(PlusCircleIcon, { fill: "var(--green-600)", size: "large" }), onClick: onAddTeamSelect, size: "medium" }, { children: "Add another team" })))] }) })));
}
/**
 * Returns the option label for a team that includes the name and age group
 * in order to help the user distinguish which team they are selecting.
 *
 * @param team The team to get the option label parts from
 * @returns A string in the format of <team name> (<age group>)
 */
export function getTeamOptionLabel(team, registeredTeams) {
    const ageGroup = team.ageGroup === 0 ? 'All ages' : ageGroupDisplay(team.ageGroup);
    const draftText = team.status === 'DRAFT' ? ' (draft)' : '';
    const registeredText = registeredTeams && registeredTeams.includes(team.id) ? '(Registered)' : '';
    return `${team.name} (${ageGroup})${draftText}${registeredText}`;
}
/**
 * Each team select needs a unique ID. This creates one using the division ID and
 * the 1-indexed "team number" as it is displayed as the select label in the UI.
 *
 * @param division The tournament division
 * @param selectIndex The array index of the select
 * @returns A string to use as the ID of a team select
 */
export function getTeamSelectId(divisionId, selectIndex) {
    return `division-${divisionId}-team-select-${selectIndex + 1}`;
}
