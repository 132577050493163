import { MutableRefObject, useState } from 'react';
import { Division } from 'redux/services/types/tournament';
import * as S from './DivisionsSideMenu.styles';

export interface DivisionSideMenuProps {
  divisions: Division[];
  refs: Map<string, MutableRefObject<HTMLElement | null>>;
}
export function DivisionsSideMenu({ divisions, refs }: DivisionSideMenuProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <S.SideMenu>
      {divisions.map((division, index) => (
        <S.MenuItem
          data-testid={getDivisionSideMenuItemId(division.id)}
          className={index === activeIndex ? 'active' : ''}
          key={`participatingTeamsSideMenu${division.id}`}
          onClick={() => {
            if (activeIndex !== index) {
              setActiveIndex(index);
              const currentDivisionsDisplayReference = refs?.get(
                division.id
              )?.current;
              if (currentDivisionsDisplayReference) {
                currentDivisionsDisplayReference.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
              }
            }
          }}
        >
          {division.name}
        </S.MenuItem>
      ))}
    </S.SideMenu>
  );
}

export function getDivisionSideMenuItemId(id: string) {
  return `division-side-menu-${id}`;
}
