import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetUserRegistrationsQuery } from '@la/services';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { StaffRegistration, TeamRegistration } from '@la/types';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetRegistrationSettingsQuery } from 'redux/services/registrationApi';
import {
  useGetInvitesQuery,
  useGetTeamRosterQuery,
} from 'redux/services/rosterManagementApi';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { AddPlayerModal } from './AddPlayerModal/AddPlayerModal';
import CSVDownloadModal from './CSVDownloadModal';
import InvitePlayerStaffModals from './InvitePlayerStaffModals';
import { RosterActionsModal } from './RosterActionsModal/RosterActionsModal';
import { InviteModal } from './RosterRollover/InviteModal/InviteModal';
import RosterSection from './RosterSection';
import RosterSideNav from './RosterSideNav';
import { Registrant } from './RosterTable';
import RosterUtilities from './RosterUtilities';
import { getFormDataBodyFromValues } from './utilities/csv';
import * as S from './RosterManagement.styles';

export type StaffInvitation = {
  link: string;
  role: string;
  id?: string;
};

export type InvitationLinks = {
  playerInviteLink: string;
  staffInviteLinks: StaffInvitation[];
};

export type RolloverData = {
  totalPlayers: number;
  totalStaff: number;
  invites: InvitationLinks;
};

const mergeTeamRegistrants = (
  finished?: Registrant[],
  pending?: TeamRegistration[]
): Registrant[] => {
  const players: Registrant[] = [
    ...(finished ?? []),
    ...(pending ?? [])
      .filter(
        (reg) =>
          reg.registrationStatus === 'PENDING_INVITE' &&
          ['INVITE_PLAYER', 'PLAYER'].includes(reg.registrationType)
      )
      .map<Registrant>((reg: TeamRegistration) => {
        return {
          avatarUrl: '',
          name: reg.metadata?.playerName || '',
          userId: parseInt(reg.registeringUserId),
          registrationStatus: 'PENDING_INVITE',
          registrationId: reg.id,
        };
      }),
  ];

  return players;
};

const mergeStaffRegistrants = (
  finished?: Registrant[],
  pending?: StaffRegistration[]
): Registrant[] => {
  const staff: Registrant[] = [
    ...(finished ?? []),
    ...(pending ?? [])
      .filter(
        (reg) =>
          reg.registrationStatus === 'PENDING_INVITE' &&
          ['INVITE_STAFF', 'STAFF'].includes(reg.registrationType)
      )
      .map<Registrant>((reg) => {
        return {
          avatarUrl: '',
          name: reg.metadata?.staffName || '',
          userId: parseInt(reg.registeringUserId),
          registrationStatus: 'PENDING_INVITE',
          isPrimaryStaff: reg.metadata?.primaryStaff,
          role: (reg as any).role ?? reg.metadata?.programStaffId.toString(),
          registrationId: reg.id,
        };
      }),
  ];

  return staff;
};

/* Roster Management Page */
export default function RosterManagement() {
  const navigate = useNavigate();

  const { siteName } = useAppSelector(getSiteIdentityData);
  const { programId, programType, teamId } = useParams();
  const [searchParams] = useSearchParams();

  const rolloverEncodedData = searchParams.get('rolloverData');
  const siteSubdomain = getLAHostnameParts().subdomain;

  const [openAddModal, setOpenAddModal] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [rolloverData, setRolloverData] = useState<RolloverData | null>(null);
  const [isRolloverModalOpen, setIsRolloverModalOpen] = useState(false);
  const isTabletPortraitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const {
    data,
    isLoading: teamDataIsLoading,
    error: teamDataError /*isError, isLoading */,
  } = useGetTeamRosterQuery(
    {
      siteSubdomain,
      teamId,
    },
    { skip: !teamId }
  );
  const { data: invitesData, isLoading: invitesDataIsLoading } =
    useGetInvitesQuery(
      {
        programId,
        siteSubdomain,
        teamId,
      },
      { skip: !programId }
    );

  const {
    data: registrationsData,
    isLoading: registrationsDataIsLoading,
    isUninitialized: isUserRegistrationQueryUninitialized,
    error: registrationsDataError,
    refetch: userRegistrationQueryRefetch,
  } = useGetUserRegistrationsQuery(
    {
      programId: programId || '',
      siteDomain: siteSubdomain,
    },
    {
      skip: !programId && !openAddModal,
    }
  );

  const { data: registrationSettings } = useGetRegistrationSettingsQuery(
    {
      programId: programId ?? '',
      subdomain: siteSubdomain,
    },
    {
      skip: !programId,
    }
  );

  const minimumNumberOfPlayers = useMemo(() => {
    return registrationSettings?.settings.find((setting) => {
      return setting.settingName === 'minimum_number_of_team_players';
    });
  }, [registrationSettings]);

  const minimumNumberOfStaff = useMemo(() => {
    return registrationSettings?.settings.find((setting) => {
      return setting.settingName === 'minimum_number_of_team_staff';
    });
  }, [registrationSettings]);

  const filteredRegistrations = (
    registrationsData?.userRegistrations as any
  )?.filter((reg: TeamRegistration) => reg.teamIdOg === teamId);

  useEffect(() => {
    if (!openAddModal && !isUserRegistrationQueryUninitialized) {
      userRegistrationQueryRefetch();
    }
  }, [
    openAddModal,
    userRegistrationQueryRefetch,
    isUserRegistrationQueryUninitialized,
  ]);

  /**
   * Serializes the encoded data into local state, cleans the URL and opens the invite modal for
   * rollovered members.
   */
  useEffect(() => {
    if (rolloverEncodedData) {
      const decodedData: RolloverData = JSON.parse(
        window.atob(rolloverEncodedData)
      );
      setRolloverData(decodedData);
      setIsRolloverModalOpen(true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, [rolloverEncodedData, searchParams]);

  const { phoneOnly, tabletPortraitUp, tabletLandscapeUp } = breakpointQueries;
  const isPhone = useMediaQuery(phoneOnly);
  const isTabletPortrait = useMediaQuery(tabletPortraitUp);
  const isTabletLandscape = useMediaQuery(tabletLandscapeUp);

  let teamNameSize: 'large' /* | 'large-mobile' */ = 'large',
    tableTitleSize: 'medium' /* | 'medium-mobile' */ = 'medium';

  if (isPhone) {
    teamNameSize = 'large'; /* 'large-mobile' once this size is added */
    tableTitleSize = 'medium' /* 'medium-mobile' once this size is added */;
  } else if (isTabletPortrait) {
    teamNameSize = 'large';
  } else if (isTabletLandscape) {
    teamNameSize = 'large'; /* 'large-mobile' once this size is added */
  }

  const formDataBody = getFormDataBodyFromValues(teamId!, 'ExportToCSV');

  const teamRoster = mergeTeamRegistrants(
    data?.players,
    filteredRegistrations as TeamRegistration[]
  );

  const staffRoster = mergeStaffRegistrants(
    data?.staff,
    filteredRegistrations as StaffRegistration[]
  ).map((staff) => {
    return {
      ...staff,
      role: invitesData?.staffInviteLinks.find(
        (role) => role.role === staff.role
      )?.role,
    };
  });

  // We need to refactor all this merging asap, this is just not optimal...
  staffRoster.forEach((staff) => {
    if (staff.role) {
      return;
    }
    const regRole = data?.staff.find(
      (staffReg) => staffReg.name === staff.name
    )?.role;
    staff.role = regRole;
  });

  useEffect(() => {
    document.title = siteName
      ? `${siteName}: Roster Management`
      : 'Roster Management';
  }, [siteName]);

  function handlePrinterRosterButtonClick(): void {
    window.open(
      `/printable?format=HTML&type=TEAM_ROSTER&issuedByRole=Captain&teamId=${teamId}&programId=${programId}`
    );
  }

  function handleRolloverRosterClick(): void {
    navigate('rollover');
  }

  const shouldRenderAddPlayerStaffModal: boolean =
    !teamDataIsLoading &&
    !teamDataError &&
    !registrationsDataIsLoading &&
    !registrationsDataError;

  return (
    <S.RosterManagementPage>
      <PageTitle>Manage your team roster</PageTitle>
      <ColumnGrid>
        <S.MinorColumn>
          <RosterSideNav
            programId={programId!}
            programType={programType!}
            teamId={teamId!}
            teamNameSize={teamNameSize}
          />
        </S.MinorColumn>
        <S.MajorColumn>
          {isTabletPortraitUp ? (
            <RosterUtilities
              onDownloadRosterCSVClick={() => setOpenModal('csvDownload')}
              onPrintRosterClick={handlePrinterRosterButtonClick}
              onRosterRolloverClick={handleRolloverRosterClick}
            />
          ) : (
            <RosterActionsModal
              onAddPlayerClick={() => setOpenAddModal('addPlayers')}
              onDownloadRosterCSVClick={() => setOpenModal('csvDownload')}
              onInvitePlayerClick={() => setOpenModal('invitePlayers')}
              onInviteStaffClick={() => setOpenModal('inviteStaff')}
              onPrintRosterClick={handlePrinterRosterButtonClick}
              onRosterRolloverClick={handleRolloverRosterClick}
            />
          )}
          {data?.players ? (
            <RosterSection
              openAddModal={() => setOpenAddModal('addPlayers')}
              openModal={() => setOpenModal('invitePlayers')}
              pendingRegistrations={registrationsData?.userRegistrations ?? []}
              programId={programId!}
              rosterData={teamRoster}
              rosterTitle="Players"
              rosterType="players"
              tableTitleSize={tableTitleSize}
              teamId={teamId!}
              minimumCapacity={minimumNumberOfPlayers?.value}
            />
          ) : null}
          {data?.staff ? (
            <RosterSection
              openModal={() => setOpenModal('inviteStaff')}
              pendingRegistrations={registrationsData?.userRegistrations ?? []}
              programId={programId!}
              rosterData={staffRoster}
              rosterTitle="Staff"
              rosterType="staff"
              tableTitleSize={tableTitleSize}
              teamId={teamId!}
              staffInviteLinks={invitesData?.staffInviteLinks}
              userRegistrationQueryRefetch={userRegistrationQueryRefetch}
              minimumCapacity={minimumNumberOfStaff?.value}
            />
          ) : null}
        </S.MajorColumn>
      </ColumnGrid>
      {shouldRenderAddPlayerStaffModal ? (
        <AddPlayerModal
          closeModal={() => setOpenAddModal(null)}
          openModalName={openAddModal}
          playerInviteLink={invitesData?.playerInviteLink}
        />
      ) : null}
      {!invitesDataIsLoading ? (
        <InvitePlayerStaffModals
          closeModalMethod={() => setOpenModal(null)}
          invitesData={invitesData}
          openModalName={openModal}
        />
      ) : null}
      {openModal === 'csvDownload' ? (
        <CSVDownloadModal
          body={formDataBody}
          closeModal={() => setOpenModal(null)}
          programId={programId!}
          programType={programType!}
        />
      ) : null}
      {rolloverData ? (
        <InviteModal
          invitationLinks={rolloverData.invites}
          onOpenChange={() => setIsRolloverModalOpen(false)}
          open={isRolloverModalOpen}
          totalPlayers={rolloverData.totalPlayers}
          totalStaff={rolloverData.totalStaff}
        />
      ) : null}
    </S.RosterManagementPage>
  );
}
/* */
