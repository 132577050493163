import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  EnvelopeIcon,
  PlusCircleIcon,
  Typography,
} from '@la/ds-ui-components';
import { getSiteId, getUserId, useAppSelector } from '@la/services';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import {
  StaffInvitation,
  StaffRegistration,
  TeamRegistration,
} from '@la/types';
import { CapacityProgressBar } from 'components/CapacityProgressBar/CapacityProgressBar';
import { useCreateStaffMutation } from 'redux/services/rosterManagementApi';
import {
  AddStaffFormFields,
  AddStaffModal,
} from './AddStaffModal/AddStaffModal';
import RosterTable, { Registrant } from './RosterTable';
import * as S from './RosterManagement.styles';

export type RosterSectionData = {
  openAddModal?: () => void;
  openModal: () => void;
  pendingRegistrations: (TeamRegistration | StaffRegistration)[];
  programId: string;
  rosterData?: Registrant[];
  rosterTitle: string;
  rosterType: 'players' | 'staff';
  tableTitleSize: 'medium' | 'large';
  teamId: string;
  staffInviteLinks?: StaffInvitation[];
  userRegistrationQueryRefetch?: () => void;
  minimumCapacity?: number;
};

/* RosterSection */
export default function RosterSection({
  openAddModal = () => {},
  openModal,
  pendingRegistrations,
  programId,
  rosterData = [],
  rosterTitle,
  rosterType,
  tableTitleSize,
  teamId,
  staffInviteLinks,
  userRegistrationQueryRefetch,
  minimumCapacity,
}: RosterSectionData) {
  const {
    registrationsRosterAddPlayer,
    registrationsRosterAddStaff,
    registrationsRosterCapacityUpdates,
  } = useFlags();

  const isTabletPortraitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);

  const [createStaff] = useCreateStaffMutation();
  const userId = useAppSelector(getUserId);
  const siteId = useAppSelector(getSiteId);

  const inviteType = rosterType === 'players' ? 'player' : 'staff';

  const pendingRegistrationsCount = rosterData.filter(
    (roster) => roster.registrationStatus === 'PENDING_INVITE'
  ).length;

  const completedRegistrationsCount = rosterData.filter(
    (roster) => roster.registrationStatus === 'SPOT_RESERVED'
  ).length;

  const onCreateStaff = (values: AddStaffFormFields) => {
    createStaff({
      programId,
      siteId,
      teamId,
      userId: userId ? userId.toString() : null,
      ...values,
    }).then(() => {
      setIsStaffModalOpen(false);
      if (userRegistrationQueryRefetch) {
        userRegistrationQueryRefetch();
      }
    });
  };

  return (
    <S.RosterSection>
      <S.SectionHead>
        <S.SectionHeadTitle>
          <Typography variant="headline" size={tableTitleSize}>
            {rosterTitle}
          </Typography>{' '}
        </S.SectionHeadTitle>
      </S.SectionHead>

      <S.ProgressBarContainer>
        {registrationsRosterCapacityUpdates && minimumCapacity ? (
          <CapacityProgressBar
            min={minimumCapacity}
            filled={completedRegistrationsCount}
            pending={pendingRegistrationsCount}
            inviteType={inviteType}
          />
        ) : null}
        {isTabletPortraitUp && inviteType === 'player' ? (
          <S.HeaderButtonsSection>
            {registrationsRosterAddPlayer ? (
              <Button
                leftIcon={
                  <PlusCircleIcon
                    fill="var(--secondary)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={openAddModal}
                size="medium"
                variant="outline"
              >
                Add player
              </Button>
            ) : null}
            <Button
              leftIcon={
                <EnvelopeIcon
                  fill="var(--secondary)"
                  size="xl"
                  variant="bold"
                />
              }
              onClick={openModal}
              size="medium"
              variant="outline"
            >
              Invite players
            </Button>
          </S.HeaderButtonsSection>
        ) : null}
        {isTabletPortraitUp && inviteType !== 'player' ? (
          <S.HeaderButtonsSection>
            {registrationsRosterAddStaff ? (
              <Button
                leftIcon={
                  <PlusCircleIcon
                    fill="var(--secondary)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={() => setIsStaffModalOpen(true)}
                size="medium"
                variant="outline"
              >
                Add {inviteType}
              </Button>
            ) : null}
            <Button
              leftIcon={
                <EnvelopeIcon
                  fill="var(--secondary)"
                  size="xl"
                  variant="bold"
                />
              }
              onClick={openModal}
              size="medium"
              variant="outline"
            >
              Get {inviteType} invite link
            </Button>
          </S.HeaderButtonsSection>
        ) : null}
      </S.ProgressBarContainer>

      {rosterData.length > 0 ? (
        <RosterTable
          pendingRegistrations={pendingRegistrations}
          programId={programId}
          registrants={rosterData}
          tableType={rosterType}
          teamId={teamId}
        />
      ) : (
        <EmptyRosterNotice
          inviteType={inviteType}
          rosterType={rosterType}
          openModal={openModal}
        />
      )}
      <AddStaffModal
        isOpen={isStaffModalOpen}
        onOpenChange={() => setIsStaffModalOpen(false)}
        staffInviteLinks={staffInviteLinks}
        onSubmit={onCreateStaff}
      />
    </S.RosterSection>
  );
}
/* */

export function EmptyRosterNotice({
  inviteType,
  rosterType,
  openModal,
}: {
  inviteType: 'player' | 'staff';
  rosterType: 'players' | 'staff';
  openModal: () => void;
}) {
  return (
    <S.EmptyRosterNotice>
      <Typography variant="headline" size="small">
        You don't have any {rosterType} in your roster.
      </Typography>
      <Typography variant="ui" size="large" weight="regular">
        Get started by inviting {rosterType}.
      </Typography>
      <Button size="large" onClick={openModal}>
        Get {inviteType} invite link
      </Button>
    </S.EmptyRosterNotice>
  );
}
