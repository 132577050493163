var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@la/ds-ui-components';
import { Provider, sendRegistrationsForInvoice, store, useGetCountriesAndAdministrativeDivisionsQuery, useGetTournamentQuery, } from '@la/services';
import { isWithinDateRange } from '@la/utilities';
import { ErrorCard } from '../../components/ErrorCard/ErrorCard';
import { MainContent, MainContentCenter, } from '../../components/MainContent/MainContent';
import { Stepper } from '../../components/Stepper/Stepper';
import { SubNavigation } from '../../components/SubNavigation/SubNavigation';
import { BaseWizard } from './BaseRegistration';
import { PaymenOptionsStep } from './PaymentOptionsStep/PaymentOptionsStep';
import { ReviewInvoices } from './ReviewInvoices/ReviewInvoices';
import { SelectPayer } from './SelectPayer/SelectPayer';
import { getMCStepTitles } from './TeamRegistration/MCRegistrationSteps';
import { TeamRegistrationContext } from './TeamRegistration/TeamRegistrationContext';
import { Wizard } from './Wizard/Wizard';
import { WizardHeader } from './Wizard/components/WizardHeader/WizardHeader';
import { ADD_PRIMARY_STAFF, ADD_TEAMS_TO_PROGRAM, API_ERROR_MESSAGE, REVIEW_REGISTRATIONS, SELECT_PAYMENT_OPTIONS, TOURNAMENT_REGISTRATION_NOT_OPEN_ERROR_MESSAGE, } from './constants';
import { usePaymentOptions } from './hooks/usePaymentOptions';
import { getNumberOfRegisteringTeams } from './utilities/getNumberOfRegisteringTeams';
import * as S from './Registration.styles';
import { WizardContent } from './Wizard/Wizard.styles';
const BaseRegistration = ({ isMC = false, includeRegistrationTriageFix = false, mcPaymentPlans = false, }) => {
    const { tournamentId, siteId } = useParams();
    const { state, setWizardState } = useContext(TeamRegistrationContext);
    const [step, setStep] = useState(1);
    const [payerTouched, setPayerTouched] = useState(false);
    const [paymentOptionTouched, setPaymentOptionTouched] = useState(false);
    const [teamsTouched, setTeamsTouched] = useState(false);
    const [saveDivisions, setSaveDivisions] = useState(false);
    const [isCreatingInvoices, setIsCreatingInvoices] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [isCalculatingPrices, setIsCalculatingPrices] = useState(false);
    if (!tournamentId) {
        throw new Error('The tournament ID is not defined');
    }
    const { data: tournament, isLoading: isTournamentLoading, error: tournamentError, } = useGetTournamentQuery({
        siteDomain: 'svssc',
        tournamentId,
        siteId,
    });
    const { paymentOptions, paymentRules, isLoading: isPaymentOptionsLoading, } = usePaymentOptions({
        siteId,
        programId: tournamentId,
    });
    const { isLoading: isCountriesLoading, isError: hasCountriesError } = useGetCountriesAndAdministrativeDivisionsQuery();
    const MCStepTitles = useMemo(() => {
        return getMCStepTitles(!mcPaymentPlans || !paymentOptions || !paymentOptions.length);
    }, [paymentOptions, mcPaymentPlans]);
    const numberOfSteps = Object.keys(MCStepTitles).length;
    if (isTournamentLoading ||
        isCountriesLoading ||
        isPaymentOptionsLoading ||
        isCalculatingPrices) {
        return (_jsx(MainContentCenter, { children: _jsx(Loader, { description: "We are gathering available divisions...", loading: true }) }));
    }
    if (isCreatingInvoices) {
        return (_jsx(MainContentCenter, { children: _jsx(Loader, { description: "Creating invoices...", loading: true }) }));
    }
    if (tournamentError || hasCountriesError) {
        return (_jsx(MainContent, { children: _jsx(WizardContent, { children: _jsx(S.RegistrationErrorContainer, { children: _jsx(ErrorCard, { message: API_ERROR_MESSAGE }) }) }) }));
    }
    const { registrationEndDate, registrationStartDate, timeZone } = tournament;
    if (tournament.divisions.length === 0 ||
        (registrationStartDate &&
            registrationEndDate &&
            !isWithinDateRange(registrationStartDate, registrationEndDate, timeZone))) {
        throw new Error(TOURNAMENT_REGISTRATION_NOT_OPEN_ERROR_MESSAGE);
    }
    let hasRegistrationsToEdit = !!(tournament === null || tournament === void 0 ? void 0 : tournament.divisions.find((division) => {
        var _a;
        return ((_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.team) &&
            division.registrationsForUser.team.find((registration) => registration.registrationStatus === 'CREATED') &&
            division.registrationsForUser.team.find((registration) => { var _a; return registration.registeringUserId === ((_a = state.payer) === null || _a === void 0 ? void 0 : _a.id.toString()); });
    }));
    const renderSteps = () => {
        switch (MCStepTitles[step]) {
            case ADD_PRIMARY_STAFF:
                return _jsx(SelectPayer, {});
            case ADD_TEAMS_TO_PROGRAM:
                return (_jsx(BaseWizard, { saveDivisions: saveDivisions, stepForward: stepForward, setWizardState: setWizardState, includeRegistrationTriageFix: includeRegistrationTriageFix, paymentOptions: paymentOptions, setIsCalculatingPrices: setIsCalculatingPrices, mcPaymentPlans: mcPaymentPlans }));
            case SELECT_PAYMENT_OPTIONS: {
                return (_jsx(PaymenOptionsStep, { paymentOptions: paymentOptions, paymentRules: paymentRules, setTotalCost: setTotalCost }));
            }
            case REVIEW_REGISTRATIONS: {
                const paymentOption = paymentOptions === null || paymentOptions === void 0 ? void 0 : paymentOptions.find((option) => option.paymentPlanId === state.selectedPaymentOption);
                return (_jsx(ReviewInvoices, { paymentOption: state.selectedPaymentOption === 'pay-in-full'
                        ? null
                        : paymentOption, totalCost: totalCost, mcPaymentPlans: mcPaymentPlans }));
            }
        }
    };
    const stepForward = () => {
        setStep((step) => {
            if (step < numberOfSteps) {
                return step + 1;
            }
            return step;
        });
    };
    const renderWizard = () => {
        var _a;
        if (!isMC) {
            return (_jsx(_Fragment, { children: hasRegistrationsToEdit ? (_jsx(Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Edit your divisions and teams", submitButtonText: "Update cart", submitUnsavedChangesErrorMessage: "Registrations were not updated in the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, includeRegistrationTriageFix: includeRegistrationTriageFix })) : (_jsx(Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Add your divisions and teams", submitButtonText: "Add to cart", submitUnsavedChangesErrorMessage: "Registrations were not added to the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, includeRegistrationTriageFix: includeRegistrationTriageFix })) }));
        }
        const registeredTeams = getNumberOfRegisteringTeams((_a = state.wizardState) === null || _a === void 0 ? void 0 : _a.divisionCardsState);
        const checkForErrors = () => {
            switch (MCStepTitles[step]) {
                case ADD_PRIMARY_STAFF:
                    return !state.payer && payerTouched;
                case ADD_TEAMS_TO_PROGRAM:
                    return registeredTeams === 0 && teamsTouched;
                case SELECT_PAYMENT_OPTIONS:
                    return !state.selectedPaymentOption && paymentOptionTouched;
                case REVIEW_REGISTRATIONS:
                    return false;
            }
        };
        const hasError = checkForErrors();
        const sendRegistrationsToWorkflow = () => __awaiter(void 0, void 0, void 0, function* () {
            const numberOfRegisteredTeams = getNumberOfRegisteringTeams(state.wizardState.divisionCardsState);
            setIsCreatingInvoices(true);
            sendRegistrationsForInvoice({
                state,
                siteId,
            })
                .then(() => {
                const invoicePagePath = `https://${window.location.host}/console/sites/${siteId}/tournaments/${tournament.id}?registeredTeams=${numberOfRegisteredTeams}`;
                window.location.assign(invoicePagePath);
            })
                .catch((err) => {
                setIsCreatingInvoices(false);
                console.error(err);
            });
        });
        const handleNextClick = () => {
            switch (MCStepTitles[step]) {
                case ADD_PRIMARY_STAFF: {
                    setPayerTouched(true);
                    if (state.payer) {
                        stepForward();
                    }
                    break;
                }
                case ADD_TEAMS_TO_PROGRAM: {
                    setTeamsTouched(true);
                    if (registeredTeams === 0) {
                        break;
                    }
                    setSaveDivisions(true);
                    break;
                }
                case SELECT_PAYMENT_OPTIONS: {
                    setPaymentOptionTouched(true);
                    if (state.selectedPaymentOption) {
                        stepForward();
                    }
                    break;
                }
                case REVIEW_REGISTRATIONS: {
                    sendRegistrationsToWorkflow();
                    break;
                }
            }
        };
        const onBackClick = () => {
            switch (MCStepTitles[step]) {
                case ADD_PRIMARY_STAFF: {
                    break;
                }
                case ADD_TEAMS_TO_PROGRAM: {
                    setSaveDivisions(false);
                    onBackwards();
                    break;
                }
                case SELECT_PAYMENT_OPTIONS: {
                    setSaveDivisions(false);
                    onBackwards();
                    break;
                }
                case REVIEW_REGISTRATIONS: {
                    onBackwards();
                    break;
                }
            }
        };
        const onBackwards = () => {
            setStep((step) => {
                if (step > 1) {
                    return step - 1;
                }
                return step;
            });
        };
        return (_jsx(Stepper, Object.assign({ currentStep: MCStepTitles[step], error: hasError, form: {}, handleNextClick: handleNextClick, numberOfTotalSteps: numberOfSteps, onBackClick: onBackClick, showDrawer: true, stepNumber: step, showSteps: true, type: "button", isMC: true }, { children: renderSteps() })));
    };
    return (_jsxs(MainContent, { children: [_jsx(WizardHeader, { isMC: isMC, tournament: tournament }), _jsx(SubNavigation, { programIdFromProps: parseInt(tournamentId) }), renderWizard()] }));
};
// Here we will fork MP registration and MC registration components
export const Registration = (props) => (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(BaseRegistration, Object.assign({}, props)) })));
