var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { getAccessToken, getBaseURL } from '@la/utilities';
import { getTournamentRegistrations } from './getTournamentRegistrations';
const baseUrl = getBaseURL();
const sendRegistrationsForInvoice = ({ state, siteId, }) => __awaiter(void 0, void 0, void 0, function* () {
    const endpoint = `${baseUrl}/api/mgmt-console/${siteId}/registrations`;
    const token = getAccessToken();
    const registrations = yield getTournamentRegistrations({
        payerId: state.payer.id.toString(),
        siteDomain: 'manager',
        siteId: siteId,
        tournamentId: state.wizardState.tournament.id,
    });
    const ngPaymentPlanId = !state.selectedPaymentOption ||
        state.selectedPaymentOption === 'pay-in-full'
        ? undefined
        : state.selectedPaymentOption;
    const payload = {
        payingUserId: state.payer.id,
        registrationSummary: createRegistrationSummary(state, registrations, ngPaymentPlanId),
        siteId: parseInt(siteId),
        version: 1,
    };
    const response = yield axios.post(endpoint, payload, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
});
export const createRegistrationSummary = (state, registrations, ngPaymentPlanId) => {
    const { divisions } = registrations;
    const registrationsForUser = divisions
        .map((division) => division.registrationsForUser.team)
        .flat();
    const registrationsToInvoice = registrationsForUser.filter((registration) => {
        return (registration.registeringUserId === state.payer.id.toString() &&
            registration.registrationStatus === 'CREATED');
    });
    return registrationsToInvoice.map((registration) => {
        var _a;
        const cost = (_a = state.wizardState.tournament) === null || _a === void 0 ? void 0 : _a.divisions.find((d) => d.id === registration.programId).cost;
        const amount = cost ? cost.toString() : '0.00';
        if (!ngPaymentPlanId) {
            return {
                amount,
                externalRegistrationId: registration.id,
                ngPaymentPlanId: ngPaymentPlanId,
                programId: parseInt(registration.programId),
                programStaffId: 14389,
                userId: state.payer.id,
                usingTeamPlayerPay: false,
                variableTeamFee: false,
            };
        }
        const summary = state.paymentPricing.find((summary) => summary.ngPaymentPlanId === ngPaymentPlanId);
        const divisionCost = summary.lineItems.find((item) => item.subprogramId === registration.programId).amount;
        return {
            amount: divisionCost.toString(),
            externalRegistrationId: registration.id,
            ngPaymentPlanId: ngPaymentPlanId,
            programId: parseInt(registration.programId),
            programStaffId: 14389,
            userId: state.payer.id,
            usingTeamPlayerPay: false,
            variableTeamFee: false,
        };
    });
};
export { sendRegistrationsForInvoice };
