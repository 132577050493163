import baseApi from './baseApi';
export const tournamentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getTournament: builder.query({
            query: ({ siteDomain, tournamentId, siteId }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}`,
                    method: 'GET',
                    params: {
                        siteId,
                    },
                };
            },
        }),
        getTournamentRegistration: builder.query({
            query: ({ siteDomain, tournamentId, payerId, siteId }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}/registration`,
                    method: 'GET',
                    params: {
                        payerId,
                        siteId,
                    },
                };
            },
        }),
        getTournamentRegistrant: builder.query({
            query: ({ siteDomain, tournamentId }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}/registrant`,
                    method: 'GET',
                };
            },
        }),
        getUserRegistrations: builder.query({
            query: ({ programId, siteDomain, payerId, siteId }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/registration`,
                    method: 'GET',
                    params: {
                        programId,
                        payerId,
                        siteId,
                    },
                };
            },
        }),
    }),
});
export const { useGetTournamentQuery, useLazyGetTournamentQuery, useGetTournamentRegistrationQuery, useGetUserRegistrationsQuery, useGetTournamentRegistrantQuery, } = tournamentApi;
