import { CheckCircle } from '@phosphor-icons/react/dist/ssr';
import { Tooltip, TooltipProvider, Typography } from '@la/ds-ui-components';
import * as S from './CapacityProgressBar.styles';

type CapacityProgressBarProps = {
  min: number;
  filled: number;
  pending: number;
  inviteType: 'player' | 'staff';
};

export const CapacityProgressBar = ({
  min,
  filled,
  pending,
  inviteType,
}: CapacityProgressBarProps) => {
  const filledPercentage = (filled / min) * 100;
  const pendingPercentage = (pending / min) * 100;
  return (
    <S.CapacityProgressBarContainer>
      <S.CapacityProgressBar>
        <TooltipProvider>
          <Tooltip
            content={`${filled} ${inviteType === 'player' ? 'players' : 'staff'} registered or added`}
            translationThreshold={filledPercentage / 2}
          >
            <S.FilledSegment $percentage={filledPercentage} />
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip
            content={`${pending} pending or started registrations`}
            translationThreshold={filledPercentage + pendingPercentage / 2}
          >
            <S.PendingSegment
              $offset={filledPercentage}
              $percentage={pendingPercentage}
            />
          </Tooltip>
        </TooltipProvider>
      </S.CapacityProgressBar>
      <S.CapacityProgressBarFooter>
        <S.CapacitySubtitleContainer>
          {filled + pending >= min ? (
            <CheckCircle size={12} weight="fill" />
          ) : null}
        </S.CapacitySubtitleContainer>
        <Typography variant="ui" size="small">
          min. {min}
        </Typography>
      </S.CapacityProgressBarFooter>
    </S.CapacityProgressBarContainer>
  );
};
