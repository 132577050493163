/* eslint-disable */
export * from './url';
export * from './auth';
export * from './data-scraping';
export * from './date';
export * from './form-fields';
export * from './string';
export * from './select';
export * from './cost';
export * from './payment-plan';
export * from './phone-number';
export * from './registration';
export * from './roster-role';
export * from './route';
export * from './zip-code';
export const hasValue = (value) => {
    return value !== '' && value !== undefined && value !== null;
};
/**
 * Given a number, returns a string representation of the value in USD.
 *
 * Examples:
 *
 *  displayCentsAsUSD(5.25) => $5.25
 *  displayCentsAsUSD(29999.99) => $29,999.99
 *
 * @param amount A number
 * @returns A string displaying the number in USD
 *
 */
export function formatAsUSD(amount, options) {
    if ((options === null || options === void 0 ? void 0 : options.hideZeroCents) && amount % 1 === 0) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(amount);
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount);
}
export function getCursorOffsetFromContainer(cursorX, cursorY, target) {
    const boundingRect = target.getBoundingClientRect();
    const deltaX = cursorX - boundingRect.x;
    const deltaY = cursorY - boundingRect.y;
    return [deltaX, deltaY];
}
export function deepObjectCopy(data) {
    return JSON.parse(JSON.stringify(data));
}
/**
 * Sorts a Record alphabetically by value.
 * @param values Key-value pairs
 * @returns Record with key-value pairs in alphabetical order
 */
export function sortRecordByValue(values) {
    return Object.fromEntries(Object.entries(values).sort((a, b) => a[1].localeCompare(b[1])));
}
export const genderNameMap = {
    any: 'Any',
    co_ed: 'Co-Ed',
    male: 'Male',
    female: 'Female',
};
export function roundToNearest(val, nearest) {
    const remainder = val % nearest;
    const valMinusRemainder = val - remainder;
    const roundedRemainder = remainder >= nearest * 0.5 ? nearest : 0;
    return valMinusRemainder + roundedRemainder;
}
export function copyTextToClipboard(textToCopy, successHandler, errorHandler) {
    navigator.clipboard.writeText(textToCopy).then(() => {
        if (successHandler !== undefined) {
            successHandler();
        }
    }, () => {
        if (errorHandler !== undefined) {
            errorHandler();
        }
    });
}
export function getFocusableChildren(parent) {
    const children = Array.from(parent.querySelectorAll(FOCUS_SELECTOR));
    return children.filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
}
export function getScrollableParent(el) {
    if (!el || el === document.body) {
        return document.body;
    }
    else if (isScrollable(el)) {
        return el;
    }
    else {
        return getScrollableParent(el.parentNode);
    }
}
function isScrollable(el) {
    const hasScrollableContent = el.scrollHeight > el.clientHeight;
    const overflowYStyle = window.getComputedStyle(el).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;
    return hasScrollableContent && !isOverflowHidden;
}
const FOCUS_SELECTOR = 'a[href], button, input:not([type="hidden"]), textarea, select, details,[tabindex]:not([tabindex="-1"])';
function isFocusableElement(el) {
    return (el.matches(FOCUS_SELECTOR) &&
        !el.hasAttribute('disabled') &&
        !el.getAttribute('aria-hidden'));
}
function findNextFocusableElement(els) {
    const activeElementIndex = els.findIndex((el) => el === document.activeElement);
    return els.find((el, index) => isFocusableElement(el) && index > activeElementIndex);
}
export function trapFocusWithin(event) {
    const { key, shiftKey } = event;
    const parent = event.currentTarget;
    const currentFocus = document.activeElement;
    if (key === 'Tab' && !!parent && !!currentFocus) {
        const hasFocusWithin = parent.contains(currentFocus);
        if (hasFocusWithin) {
            event.preventDefault();
            const focusableElements = getFocusableChildren(parent), children = Array.from(parent.querySelectorAll('*')).flat();
            if (shiftKey) {
                const nextFocus = findNextFocusableElement(children.reverse());
                if (!!nextFocus) {
                    nextFocus.focus();
                }
                else if (!!focusableElements.length) {
                    focusableElements[focusableElements.length - 1].focus();
                }
            }
            else {
                const nextFocus = findNextFocusableElement(children);
                if (!!nextFocus) {
                    nextFocus.focus();
                }
                else if (!!focusableElements.length) {
                    focusableElements[0].focus();
                }
            }
        }
    }
}
export function detectOutsideEvent(event, els, onOutsideClick) {
    var _a;
    let path = (_a = event.composedPath()) !== null && _a !== void 0 ? _a : [];
    if (els.every((el) => !path.includes(el))) {
        onOutsideClick();
    }
}
/**
 * Returns a subset of length `n` from a larger array.
 * @param array The array to be splitted
 * @param chunkSize The maximum size each inner array should have
 * @returns A 1xN matrix where n <= chunkSize
 */
export function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}
